import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  connectorsForWallets,
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { 
  injectedWallet,
  walletConnectWallet, 
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  optimismGoerli,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { bubsTestnet, modularTestnet, opDevnet } from "./utils/chainConfig";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Leaderboard } from "./Leaderboard";
import { Bitski } from "bitski";
import { bitskiWallet } from "./components/Bitski/bitskiWagmi";

const WALLETCONNECT_PROJECT_ID = 'cd01adc3ac42c1df853aa91ed7d89dae'

const { chains, publicClient } = configureChains(
  [modularTestnet],
  [
    publicProvider()
  ]
);

const bitski = new Bitski(
  '30f883ce-e3b7-4f40-be5e-76a5663176de',
  window.location.origin + '/callback.html',
);

const connectors = connectorsForWallets([
  // {
  //   groupName: 'Recommended',
  //   wallets: [
  //     bitskiWallet({ bitski, chains }),
  //   ],
  // },
  {
    groupName: 'Wallets',
    wallets: [
      injectedWallet({ chains }),
      // walletConnectWallet({ chains, projectId: WALLETCONNECT_PROJECT_ID }),
      metaMaskWallet({ chains, projectId: WALLETCONNECT_PROJECT_ID }),
      coinbaseWallet({ appName: 'Celestia 2048', chains }),
    ],
  },
])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/leaderboard",
    element: <Leaderboard />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains}>
      <RouterProvider router={router} />
      <ToastContainer position="top-center" />
    </RainbowKitProvider>
  </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
