export const modularTestnet = {
  id: parseInt(process.env.REACT_APP_MODULAR_GAME_CHAIN_ID as string),
  name: process.env.REACT_APP_MODULAR_GAME_CHAIN_NAME!,
  network: process.env.REACT_APP_MODULAR_GAME_CHAIN_NAME!.toLowerCase().replaceAll(' ', '-'),
  iconUrl: '/img/chains/upnode.webp',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://" + process.env.REACT_APP_MODULAR_GAME_RPC],
      webSocket: [`wss://${process.env.REACT_APP_MODULAR_GAME_RPC}`],
    },
    public: {
      http: ["https://" + process.env.REACT_APP_MODULAR_GAME_RPC],
      webSocket: [`wss://${process.env.REACT_APP_MODULAR_GAME_RPC}`],
    }
  },
  blockExplorers: {
    default: {
      name: "Blockscout",
      url: process.env.REACT_APP_MODULAR_GAME_EXPLORER as string,
    }
  },
  contracts: {
    // multicall3: {
    //   address: "0xca11bde05977b3631167028862be2a173976ca11",
    //   blockCreated: 49461,
    // },
  },
  testnet: true,
}

export const opDevnet = {
  id: 901,
  name: 'Optimism v1.2.0',
  network: 'optimism-v1.2.0',
  iconUrl: '/img/chains/upnode.webp',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["http://192.168.100.110:9545"],
    },
    public: {
      http: ["http://192.168.100.110:9545"],
    }
  },
  blockExplorers: {
    default: {
      name: "Blockscout",
      url: "http://192.168.100.110:9545",
    }
  },
  contracts: {
    // multicall3: {
    //   address: "0xca11bde05977b3631167028862be2a173976ca11",
    //   blockCreated: 49461,
    // },
  },
  testnet: true,
}

export const bubsTestnet = {
  id: 1582,
  name: 'Bubs Testnet',
  network: 'bubs-testnet',
  iconUrl: '/img/chains/caldera.png',
  nativeCurrency: {
    name: 'GETH',
    symbol: 'GETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://bubs.calderachain.xyz/http"],
    },
    public: {
      http: ["https://bubs.calderachain.xyz/http"],
    }
  },
  blockExplorers: {
    default: {
      name: "Blockscout",
      url: "https://bubs.calderaexplorer.xyz",
    }
  },
  contracts: {
    // multicall3: {
    //   address: "0xca11bde05977b3631167028862be2a173976ca11",
    //   blockCreated: 49461,
    // },
  },
  testnet: true,
}