// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  background-color: #bbada0;
  border: 8px solid #bbada0;
  border-radius: 6px;
}
.grid-cell {
  width: 84px;
  height: 84px;
  margin: 8px;
  border-radius: 4px;
  background: rgba(238, 228, 218, 0.35);
}
`, "",{"version":3,"sources":["webpack://./src/styles/core.less","webpack://./src/components/Grid/grid.less"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;ACHF;ADMA;EACE,eAAA;EACA,iBAAA;ACJF;ADOA;EACE,cAAA;ACLF;ADOE;EACE,qBAAA;ACLJ;AAjBA;EACE,aAAA;EACA,eAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;AAmBF;AAjBE;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qCAAA;AAmBJ","sourcesContent":["@import \"variables.less\";\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #faf8ef;\n  color: #776e65;\n  font-size: @pixel-size * 2.25;\n  line-height: @pixel-size * 3.5;\n}\n\nh1 {\n  font-size: 4.5 * @pixel-size;\n  line-height: 5.75 * @pixel-size;\n}\n\na {\n  color: #776e65;\n\n  &:hover {\n    text-decoration: none;\n  }\n}\n","@import \"../../styles/index.less\";\n\n.grid {\n  display: flex;\n  flex-wrap: wrap;\n  background-color: #bbada0;\n  border: @pixel-size solid #bbada0;\n  border-radius: @pixel-size * 0.75;\n\n  &-cell {\n    width: @pixel-size * 10.5;\n    height: @pixel-size * 10.5;\n    margin: @pixel-size * 1;\n    border-radius: @pixel-size * 0.5;\n    background: rgba(238, 228, 218, 0.35);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
