import React, { useEffect, useState } from "react";
import { Button } from "./components/Button";
import { Game } from "./components/Game";

import "@rainbow-me/rainbowkit/styles.css";
import "./App.less";
import { Link } from "react-router-dom";

/* eslint-disable react/jsx-no-target-blank */
export const App = () => {
  const [date, setDate] = useState<Date>(new Date());

  const handleRestart = () => {
    setDate(new Date());
  };

  return (
    <div className="App">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "49% 51%",
          marginBottom: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: 30,
            paddingRight: 15,
            marginTop: 16,
            borderRight: "2px dashed rgb(119, 110, 101)"
          }}
        >
          {/* <div style={{ paddingTop: 8 }}>
            <img src="/img/hokum-logo-text.svg" style={{ height: 28 }}></img>
          </div> */}

          <div style={{ fontWeight: "bold", fontSize: 36, letterSpacing: 10, marginTop: 12 }}>
            2048
          </div>

          <div style={{ fontWeight: "bold", fontSize: 18, marginBottom: 12, marginTop: 8 }}>
            Scaled with Celestia
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              marginBottom: 16,
              gap: 28,
              fontWeight: "bold",
            }}
          >
            <Link to="/">
              <div className={`nav-button active`}>Play</div>
            </Link>
            
            <Link to="/leaderboard">
              <div className={`nav-button`}>Leaderboard</div>
            </Link>
          </div>
        </div>

        <div style={{
          paddingLeft: 28,
          paddingRight: 30,
          marginTop: 20,
        }}>
          <div style={{ fontWeight: "bold", fontSize: 20 }}><img src="/img/hokum-logo-text.svg" style={{ height: 15 }}></img> Point</div>
          <div style={{ fontSize: 12, marginTop: -6 }}>(Updated weekly)</div>

          <div style={{ fontWeight: "bold", fontSize: 30, marginTop: 4, marginBottom: 12 }}>---,---</div>

          {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ fontSize: 20, fontWeight: "bold" }}>1.00x</div>
            <div className="hokum-point-boost-btn">
              <Button>Boost</Button>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className="header">
        <div>
          <Button onClick={handleRestart}>Restart</Button>
        </div>
      </div> */}
      <Game key={date.toISOString()} />

      {/* <div>
        <p>
          <b>Wondering how was that built?</b> You can find a video tutorial and
          code here:
        </p>
        <ul>
          <li>
            <a href="https://youtu.be/vI0QArPnkUc" target="_blank">
              Tutorial (YouTube video)
            </a>
          </li>
          <li>
            <a
              href="https://github.com/mateuszsokola/2048-in-react/"
              target="_blank"
            >
              Source Code (Github)
            </a>
          </li>
          <li>
            <a
              href="https://mateuszsokola.github.io/2048-animation-examples/"
              target="_blank"
            >
              Animation Examples (Github Pages)
            </a>
          </li>
        </ul>
        <p>
          This game (2048) was built using <b>React</b> and <b>TypeScript</b>.
          The unique part of this example is animations. The animations in React
          aren't that straightforward, so I hope you can learn something new
          from it.
        </p>
      </div>
      <div className="footer">
        Made with ❤️ by{" "}
        <a
          href="https://www.youtube.com/channel/UCJV16_5c4A0amyBZSI4yP6A"
          target="_blank"
        >
          Matt Sokola
        </a>
      </div> */}
    </div>
  );
};
/* eslint-enable react/jsx-no-target-blank */
