import { toast } from "react-toastify";
import { ContractFunctionExecutionError } from "viem";
import { restartGame } from "./common";

export function handleViemError(err: any, genericMessage: string, address?: string) {
  console.error(err)

  if (err instanceof ContractFunctionExecutionError) {
    const message = err.shortMessage

    if (message) {
      const reasonLeading = 'reverted with the following reason:'
      const reasonIndex = message.indexOf(reasonLeading)

      if (reasonIndex != -1) {
        const reason = message.substring(reasonIndex + reasonLeading.length).trim()
        if (reason == 'Not board owner') {
          // toast.error(reason + '! Restarting...')
          restartGame(address)
        } else {
          toast.warn(reason)
        }
        
      } else {
        if (message.indexOf('of executing this transaction exceeds the balance of the account') != -1) {
          toast.error('Out of gas! Please request from the faucet and try again.')
          return;
        }

        toast.error(message)
      }

      return;
    }
  }

  if (err.shortMessage == 'User rejected the request.') {
    return;
  }

  toast.error(err.shortMessage || err.message || genericMessage)
}