import { useCallback, useEffect, useReducer, useState } from "react";
import { useAccount, useBalance, useContractRead, useContractWrite, useNetwork, usePublicClient, useSendTransaction } from "wagmi";
import Game2048ABI from "../abis/Game2048.json"
import Token2048ABI from "../abis/Token2048.json"
import { createWalletClient, parseEther, http, webSocket } from "viem";
import { privateKeyToAccount } from 'viem/accounts'
import { useTransactionReceiptFn } from "./useTransactionReceiptFn";
import { TileMeta } from "../components/Tile";
import { Action, GameReducer, State, initialState } from "../components/Game/hooks/useGame/reducer";
import { randomPk } from "../utils/common";
import { toast } from "react-toastify";
import { handleViemError } from "../utils/viemError";
import useRequestFaucet from "./useRequestFaucet";

const HOT_WALLET_FUND_CUTOFF = parseEther('0.000005')
const HOT_WALLET_FUND_CRITICAL = parseEther('0.000004')
const HOT_WALLET_FUND_AMOUNT = parseEther('0.00001')
const HOT_WALLET_FUND_SAFETY = parseEther('0.000005')

// For mockup
const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

function getGasPrice(chainId: number) {
  switch (chainId) {
    case 10:
    case 420:
    case 8453:
    case 84531:
      return BigInt("10000000")

    case 80001:
      return BigInt("100000000000")

    default:
      return BigInt("10000")
  }
}

export function useChainActions(state: State, dispatch: React.Dispatch<Action>) {
  const getTransactionReceipt = useTransactionReceiptFn()
  const publicClient = usePublicClient()
  const { chain } = useNetwork()
  const { address, isConnected } = useAccount()
  const [ isMoving, setIsMoving ] = useState(false)
  const [ chainNewTile, setChainNewTile ] = useState(-1)
  const [ boardId, setBoardId ] = useState(-1)
  const [ moveIndex, setMoveIndex ] = useState(0)

  // const [ state, dispatch ] = useReducer(GameReducer, initialState);
  const [ chainScore, setChainScore ] = useState(0)
  const [ hotWalletAccount, setHotWalletAccount ] = useState<any>()
  const [ hotWalletClient, setHotWalletClient ] = useState<any>()
  const [ useHotWalletClient, setUseHotWalletClient ] = useState(false)
  const [ hotWalletOutOfFund, setHotWalletOutOfFund ] = useState(false)
  // const [ hotWalletNeedFund, setHotWalletNeedFund ] = useState(false)

  const { writeAsync: move } = useContractWrite({
    address: process.env.REACT_APP_GAME_CONTRACT as `0x${string}`,
    abi: Game2048ABI,
    functionName: 'move',
  })

  const { writeAsync: createBoard } = useContractWrite({
    address: process.env.REACT_APP_GAME_CONTRACT as `0x${string}`,
    abi: Game2048ABI,
    functionName: 'createBoard',
  })

  const { data: boardData, refetch: boardRefetch } = useContractRead({
    address: boardId == -1 ? undefined : process.env.REACT_APP_GAME_CONTRACT as `0x${string}`,
    abi: Game2048ABI,
    functionName: 'getBoard',
    args: [boardId],
  })

  const { data: hotWalletBalance, refetch: hotWalletRefetch } = useBalance({
    address: hotWalletAccount?.address,
  })

  const { sendTransactionAsync: fundHotWalletFn } = useSendTransaction({
    to: hotWalletAccount?.address,
    value: HOT_WALLET_FUND_AMOUNT,
  })

  const requestFaucet = useRequestFaucet()

  // For manual funding from user
  const fundHotWallet = useCallback(async () => {
    try {
      if (address && isConnected) {
        const currentBalance = await publicClient.getBalance({
          address,
        })
  
        if (currentBalance < HOT_WALLET_FUND_AMOUNT + HOT_WALLET_FUND_SAFETY) {
          return toast.error("Please request faucet and try again")
        }
  
        const tx = await fundHotWalletFn()
  
        await getTransactionReceipt(tx.hash)

        hotWalletRefetch()
      } else {
        toast.error("Please connect your wallet")
      }
    } catch (err) {
      console.error(err)
      handleViemError(err, "Refill Gas failed! Please try again.")
    }
  }, [ fundHotWalletFn, address, isConnected, publicClient, getTransactionReceipt, hotWalletRefetch ]) 

  // Buggy
  // useEffect(() => {
  //   if (hotWalletNeedFund && hotWalletAccount) {
  //     requestFaucet(hotWalletAccount.address).catch(err => console.error(err))
  //   }
  // }, [hotWalletNeedFund, hotWalletAccount])

  useEffect(() => {
    if (useHotWalletClient && hotWalletBalance && hotWalletAccount) {
      console.log(hotWalletBalance.value)

      if (hotWalletBalance.value < HOT_WALLET_FUND_CUTOFF) {
        // setHotWalletNeedFund(true)
        requestFaucet(hotWalletAccount.address).catch(err => console.error(err))
      }

      if (hotWalletBalance.value < HOT_WALLET_FUND_CRITICAL) {
        setHotWalletOutOfFund(true)
      } else {
        setHotWalletOutOfFund(false)
      }
    }
  }, [hotWalletBalance, useHotWalletClient, hotWalletAccount])

  // Generate in-browser hot wallet if not generated
  useEffect(() => {
    if (chain) {
      let hotPk: `0x${string}` = window.localStorage.getItem(`MODULARGAMES_${address}_HOT_PK`) as `0x${string}`

      if (!hotPk) {
        hotPk = randomPk()
        window.localStorage.setItem(`MODULARGAMES_${address}_HOT_PK`, hotPk)
      }
  
      const account = privateKeyToAccount(hotPk)
      setHotWalletAccount(account)
  
      const ws = new WebSocket(`wss://${process.env.REACT_APP_MODULAR_GAME_RPC}`)
  
      ws.onopen = () => {
        console.log('USE WEBSOCKET')
  
        const client = createWalletClient({
          account,
          chain,
          transport: webSocket(`wss://${process.env.REACT_APP_MODULAR_GAME_RPC}`),
          pollingInterval: 500,
        })
        setHotWalletClient(client)
        hotWalletRefetch()
  
        ws.close()
      }
      
      ws.onerror = () => {
        console.log('USE HTTPS')
  
        const client = createWalletClient({
          account,
          chain,
          transport: http(`https://${process.env.REACT_APP_MODULAR_GAME_RPC}`),
          pollingInterval: 500,
        })
        setHotWalletClient(client)
        hotWalletRefetch()
      }
    }
  }, [chain])

  useEffect(() => {
    if (boardData) {
      const tiles = (boardData as any).tiles;
      const score = (boardData as any).score;

      const board = [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ]

      console.log('Score:', score)
      setChainScore(parseInt(score))
      
      for (let i = 0; i < tiles.length; i++) {
        const row = Math.floor(i / 4)
        const col = i % 4

        board[row][col] = parseInt(tiles[i])
      }

      console.log(board)

      // Compare with tiles

      const tilesBoard = [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ]
    
      for (const tile of Object.values(state.tiles)) {
        tilesBoard[tile.position[1]][tile.position[0]] = tile.value
      }

      // if (JSON.stringify(tilesBoard) != JSON.stringify(board)) {
      //   console.log('Board mismatch!')

      //   dispatch({
      //     type: "REPLACE_TILES",
      //     board,
      //   })
      // }

      setTimeout(() => {
        dispatch({
          type: "REPLACE_TILES",
          board,
        })
      }, 300)

      setMoveIndex(parseInt((boardData as any).moves))

      if ((boardData as any).owner == (boardData as any).controller) {
        setUseHotWalletClient(false)
      } else {
        setUseHotWalletClient(true)
      }
    }
  }, [boardData])

  useEffect(() => {
    if (boardId > -1) {
      window.localStorage.setItem(`MODULARGAMES_${address}_BOARD_ID`, boardId.toString())
    } else {
      window.localStorage.removeItem(`MODULARGAMES_${address}_BOARD_ID`)
    }
  }, [boardId])

  const chainMove = useCallback(async (dir: number) => {
    try {
      setIsMoving(true)

      // Fetch moveIndex
      // const board = await publicClient.readContract({
      //   address: process.env.REACT_APP_GAME_CONTRACT as `0x${string}`,
      //   abi: Game2048ABI,
      //   functionName: 'getBoard',
      //   args: [boardId],
      // })

      let tx: { hash: `0x${string}` }

      if (useHotWalletClient) {
        console.log('Start')

        // let gas = chainScore < 30000 ? 10000000 : await publicClient.estimateContractGas({
        //   address: process.env.REACT_APP_GAME_CONTRACT as `0x${string}`,
        //   abi: Game2048ABI,
        //   functionName: 'move',
        //   args: [boardId, moveIndex, dir],
        //   // gas: BigInt(300000),
        //   account: hotWalletAccount,
        //   maxFeePerGas: getGasPrice(chain?.id || 0),
        //   maxPriorityFeePerGas: getGasPrice(chain?.id || 0),
        // })

        // if (gas) {
        //   console.log("gas", gas)
        //   gas = BigInt(Math.floor(parseInt(gas.toString()) * 1.4))
        // }
        
        const request = {
          address: process.env.REACT_APP_GAME_CONTRACT as `0x${string}`,
          abi: Game2048ABI,
          functionName: 'move',
          args: [boardId, moveIndex, dir],
          gas: 5000000,
          maxFeePerGas: getGasPrice(chain?.id || 0),
          maxPriorityFeePerGas: getGasPrice(chain?.id || 0),
        }

        console.log('Simulated')
        tx = {
          hash: await hotWalletClient.writeContract(request)
        }
        console.log('Finished')
      } else {
        tx = await move({
          args: [boardId, moveIndex, dir],
          gas: BigInt(chainScore < 30000 ? 5000000 : 5000000),
          gasPrice: getGasPrice(chain?.id || 0),
        });
      }

      const receipt = await getTransactionReceipt(tx.hash)

      console.log(receipt.logs)

      const moveLog = receipt.logs.find(log => log.topics[0] == "0x3620b51449e432fe41798eed617c247d320e66b79e47ca366856d94160ad2c7e")
      const position = parseInt(moveLog?.data.slice(0, 66) || '0')

      setChainNewTile(position)

      // console.log(receipt)
      // console.log(position)
      
      boardRefetch()
      hotWalletRefetch()

      setMoveIndex(moveIndex + 1)
    } finally {
      setIsMoving(false)
    }
  }, [move, moveIndex, chainScore, getTransactionReceipt, boardId, setIsMoving, address, isConnected, hotWalletClient, useHotWalletClient])

  const chainCreateBoard = useCallback<(useHotWallet: boolean) => Promise<[number, number]>>(async (useHotWallet: boolean) => {
    if (!address) {
      throw new Error("Connecting Wallet... Please try again")
    }

    await requestFaucet(address)

    if (useHotWallet && hotWalletAccount.address) {
      await requestFaucet(hotWalletAccount.address)
    }

    const nonce = Math.floor(Math.random() * 1000000000)

    // console.log(hotWalletAccount)

    const tx = await createBoard({
      args: [
        address,
        useHotWallet ? hotWalletAccount.address : address,
        "0x0000000000000000000000000000000000000000",
        nonce,
        0,
      ],
      value: useHotWallet ? parseEther('0') : parseEther('0'),
      gasPrice: getGasPrice(chain?.id || 0),
    })

    const receipt = await getTransactionReceipt(tx.hash)

    console.log(receipt)

    const createBoardLog = receipt.logs.find(log => log.topics[0] == "0x8113cadb7c56b6ec804e810e06f9c3fda193d3c33b4cf0837732930c2f288698")
    const position = parseInt(createBoardLog?.data.substring(0, 66) || '0')
    const row = Math.floor(position / 4)
    const col = position % 4

    console.log(createBoardLog)

    setBoardId(parseInt(createBoardLog?.topics[3]!) ?? -1)
    // setBoardId(1000)

    await wait(500);

    setUseHotWalletClient(useHotWallet)
    hotWalletRefetch()

    return [col, row]
  }, [createBoard, getTransactionReceipt, setBoardId, address, hotWalletAccount, isConnected])

  const resetChainNewTile = useCallback(async () => {
    setChainNewTile(-1)
  }, [ setChainNewTile ])

  const cancelMoving = useCallback(async () => {
    setIsMoving(false)
  }, [ setIsMoving ])

  const loadGame = useCallback(async (boardId: number) => {
    setBoardId(boardId)
  }, [ setBoardId ])

  return {
    isMoving,
    chainMove,
    chainCreateBoard,
    chainNewTile,
    resetChainNewTile,
    cancelMoving,
    boardId,
    loadGame,
    chainScore,
    useHotWalletClient,
    hotWalletOutOfFund,
    fundHotWallet,
  }
}
