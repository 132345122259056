// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/core.less","webpack://./src/styles/index.less"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;ACHF;ADMA;EACE,eAAA;EACA,iBAAA;ACJF;ADOA;EACE,cAAA;ACLF;ADOE;EACE,qBAAA;ACLJ","sourcesContent":["@import \"variables.less\";\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #faf8ef;\n  color: #776e65;\n  font-size: @pixel-size * 2.25;\n  line-height: @pixel-size * 3.5;\n}\n\nh1 {\n  font-size: 4.5 * @pixel-size;\n  line-height: 5.75 * @pixel-size;\n}\n\na {\n  color: #776e65;\n\n  &:hover {\n    text-decoration: none;\n  }\n}\n","body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #faf8ef;\n  color: #776e65;\n  font-size: 18px;\n  line-height: 28px;\n}\nh1 {\n  font-size: 36px;\n  line-height: 46px;\n}\na {\n  color: #776e65;\n}\na:hover {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
