import React, { useCallback, useEffect } from "react"
import { toast } from "react-toastify"
import { useAccount, usePublicClient } from "wagmi"

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export default function useRequestFaucet() {
  const publicClient = usePublicClient({ chainId: parseInt(process.env.REACT_APP_MODULAR_GAME_CHAIN_ID!) })

  const requestFaucet = useCallback(async (address: `0x${string}`) => {
    try {
      if (address) {
        let balance = await publicClient.getBalance({ address })
  
        if (balance < BigInt('5000000000000')) {
          await fetch('https://faucet.hokum.gg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                network: process.env.REACT_APP_FAUCET_NETWORK,
                walletAddress: address,
            })
          })

          // Wait until faucet success
          while (balance < BigInt('5000000000000')) {
            await wait(500)
            balance = await publicClient.getBalance({ address })
          }
        }
      }
    } catch (err) {
      console.error(err)
      toast.warn("Automated Faucet Error")
    }
  }, [publicClient])

  return requestFaucet;
}