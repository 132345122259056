import { cloneDeep } from "lodash";
import { TileMeta } from "../../../Tile";
import { nextSeqId } from "../useIds";

export type State = {
  tiles: {
    [id: number]: TileMeta;
  };
  inMotion: boolean;
  hasChanged: boolean;
  byIds: number[];
};

export const initialState: State = {
  tiles: {},
  byIds: [],
  hasChanged: false,
  inMotion: false,
};

export type Action =
  | { type: "CREATE_TILE"; tile: TileMeta }
  | { type: "UPDATE_TILE"; tile: TileMeta }
  | { type: "MERGE_TILE"; source: TileMeta; destination: TileMeta }
  | { type: "REPLACE_TILES"; board: number[][]; }
  | { type: "START_MOVE" }
  | { type: "END_MOVE" };

export const GameReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "CREATE_TILE":
      return {
        ...state,
        tiles: {
          ...state.tiles,
          [action.tile.id]: action.tile,
        },
        byIds: [...state.byIds, action.tile.id],
        hasChanged: false,
      };
    case "UPDATE_TILE":
      return {
        ...state,
        tiles: {
          ...state.tiles,
          [action.tile.id]: action.tile,
        },
        hasChanged: true,
      };
    case "MERGE_TILE":
      const {
        [action.source.id]: source,
        [action.destination.id]: destination,
        ...restTiles
      } = state.tiles;
      return {
        ...state,
        tiles: {
          ...restTiles,
          [action.destination.id]: {
            id: action.destination.id,
            value: action.source.value + action.destination.value,
            position: action.destination.position,
          },
        },
        byIds: state.byIds.filter((id) => id !== action.source.id),
        hasChanged: true,
      };
    case "REPLACE_TILES":
      const tiles = cloneDeep(state.tiles)

      const replaced = [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ]

      for (let id in tiles) {
        const [ col, row ] = tiles[id].position
        if (action.board[row][col] > 0) {
          tiles[id].value = action.board[row][col]
        } else {
          delete tiles[id]
        }
        
        replaced[row][col] = 1
      }

      for (let row = 0; row < 4; row++) {
        for (let col = 0; col < 4; col++) {
          if (replaced[row][col]) continue;
          if (!action.board[row][col]) continue;

          const id = nextSeqId()
          
          tiles[id] = {
            id,
            position: [col, row],
            value: action.board[row][col],
          }
        }
      }

      console.log(tiles)

      const byIds = Object.keys(tiles).map(x => parseInt(x)).sort()

      return {
        ...state,
        byIds,
        tiles,
        hasChanged: false,
      }
    case "START_MOVE":
      return {
        ...state,
        inMotion: true,
      };
    case "END_MOVE":
      return {
        ...state,
        inMotion: false,
      };
    default:
      return state;
  }
};
