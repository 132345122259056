import React, { useEffect, useState } from "react";
import { BoardProvider } from "./context/BoardContext";
import { boardMargin, tileCount as defaultTileCount } from "./models/Board";
import { Grid } from "../Grid";
import { TileMeta, tileTotalWidth, Tile } from "../Tile";
import "./board.less";
import { Button } from "../Button";
import { useAccount, useNetwork } from "wagmi";
import BoardWaitForTx from "./BoardWaitForTx";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { restartGame } from "../../utils/common";

type Props = {
  tiles: TileMeta[];
  tileCountPerRow: number;
  chainCreateBoardWrapper: (useHotWallet: boolean) => void;
  cancelMoving: () => void;
  score: number;
  isMoving: boolean;
  isInitalized: boolean;
  isInitializing: boolean;
  isEndGame: boolean;
  boardId: number;
  loadGame: (boardId: number) => void;
  useHotWalletClient: boolean;
  hotWalletOutOfFund: boolean;
  fundHotWallet: () => any;
};

export const Board = ({ 
  tiles,
  tileCountPerRow = defaultTileCount,
  chainCreateBoardWrapper,
  score,
  isMoving,
  isInitalized,
  isInitializing,
  isEndGame,
  boardId,
  loadGame,
  useHotWalletClient,
  hotWalletOutOfFund,
  fundHotWallet,
}: Props) => {
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()

  const [ initDelay, setInitDelay ] = useState(true)
  const [ useHotWalletCheckbox, setUseHotWalletCheckbox ] = useState(true)

  // container width = tile width * tile count per row
  const containerWidth = tileTotalWidth * tileCountPerRow;
  // board width = container width + margin
  const boardWidth = containerWidth + boardMargin;

  // render all tiles on the board
  const tileList = tiles.map(({ id, ...restProps }) => (
    <Tile key={`tile-${id}`} {...restProps} zIndex={id} />
  ));

  const isConnectedToHokum = isConnected && chain?.id == parseInt(process.env.REACT_APP_MODULAR_GAME_CHAIN_ID!)

  useEffect(() => {
    if (!address || isConnected) {
      setTimeout(() => {
        setInitDelay(false)
      }, 1000)

      const boardId = window.localStorage.getItem(`MODULARGAMES_${address}_BOARD_ID`)

      if (boardId && !isNaN(parseInt(boardId))) {
        loadGame(parseInt(boardId))
      }
    }
  }, [address, isConnected])

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: 420,
        }}
      >
        <div>
          {boardId >= 0 && <div style={{ fontSize: "0.9rem" }}>Game ID #{boardId}</div>}
          <div style={{ fontWeight: "bold", fontSize: "1.3rem" }}>Score: {score}</div>
        </div>
        <ConnectButton showBalance={false} chainStatus={"icon"} accountStatus={"address"} />
      </div>

      <div className="board" style={{ width: boardWidth }}>
        <BoardProvider
          containerWidth={containerWidth}
          tileCount={tileCountPerRow}
        >
          <div className="tile-container">{tileList}</div>
          <Grid />
          {!isInitalized && (
            initDelay ? (
              <div className="board-overlay">
                <div className="spinner-border"></div>
              </div>
            ) : (
              <div className="board-overlay">
                {isConnectedToHokum ? (<>
                  {isInitializing && <BoardWaitForTx />}
                  {!isInitalized && !isInitializing && (
                    <>
                      {/* <div style={{ marginBottom: 12 }}>
                        <Button onClick={() => window.open('https://testnet-faucet.modulargames.xyz')}>Faucet</Button>
                      </div> */}
                      <Button onClick={() => chainCreateBoardWrapper(useHotWalletCheckbox)}>New Game</Button>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
                        <input type="checkbox" checked={useHotWalletCheckbox} onChange={e => setUseHotWalletCheckbox(e.target.checked)}></input>
                        <div style={{ fontSize: "1rem", marginLeft: 4 }}>Session Key</div>
                      </div>
                    </>
                  )}
                </>) : (
                  <ConnectButton showBalance={false} chainStatus={"icon"} accountStatus={"address"}></ConnectButton>
                )}
              </div>
            )
          )}
          {isMoving && (
            <div className="board-overlay">
              <BoardWaitForTx showConfirmText={!useHotWalletClient} />
            </div>
          )}
          {/* {hotWalletOutOfFund && (
            <div className="board-overlay">
              <div style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: 16,
              }}>Relayer Out of Gas</div>

              <div style={{ marginBottom: 12 }}>
                <Button onClick={() => window.open('https://testnet-faucet.modulargames.xyz')}>Faucet</Button>
              </div>

              <Button onClick={() => fundHotWallet()}>Refill Gas</Button>
            </div>
          )} */}
          {isEndGame && (
            <div className="board-overlay">
              <div style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginBottom: 24,
              }}>Game Over</div>
              <Button onClick={() => restartGame(address)}>New Game</Button>
            </div>
          )}
        </BoardProvider>
      </div>

      <div style={{ fontSize: "1rem", textAlign: "center" }}>
        Scores are automatically saved on-chain as you play
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: 8 }}>
        {/* <Button onClick={handleRestart}>Restart</Button> */}
        <div style={{ marginLeft: 8, marginRight: 8 }}>
          <Button onClick={() => {
            if (window.confirm('Are you sure you want to restart the game?')) {
              restartGame(address)
            }
          }}>Restart</Button>
        </div>
        <div style={{ marginLeft: 8, marginRight: 8 }}>
          <Button onClick={() => {
            const gameId = window.prompt('Please enter your Game ID')
            if (gameId !== null) {
              loadGame(parseInt(gameId))
            }
          }}>Load Game</Button>
        </div>
      </div>
    </div>
  );
};
