import React, { useCallback, useEffect, useState } from "react";
import { Button } from "./components/Button";
import { Game } from "./components/Game";

import "@rainbow-me/rainbowkit/styles.css";
import "./App.less";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Link } from "react-router-dom";
import { addrParse } from "./utils/common";
import { request, gql } from 'graphql-request'

interface LeaderboardItem {
  address: string
  score: number
  totalScore: number
  // boardId: number
}

const LEADERBOARD_QUERY = gql`
query MyQuery {
  leaderboards(first: 100, orderBy: score, orderDirection: desc) {
    blockTimestamp
    boardId
    id
    score
    totalScore
  }
}
`

/* eslint-disable react/jsx-no-target-blank */
export const Leaderboard = () => {
  const [ leaderboard, setLeaderboard ] = useState<LeaderboardItem[]>([])

  const fetchLeaderboard = useCallback(async () => {
    const gqlResponse: any = await request({
      url: process.env.REACT_APP_SUBGRAPH!,
      document: LEADERBOARD_QUERY,
      // requestHeaders: {
      //   Authorization: "Basic ZGV2OlRxbmdGSU1raDFPbVlsYWg=",
      // }
    })

    console.log(gqlResponse)

    setLeaderboard(gqlResponse.leaderboards.map((leaderboard: any) => ({
      address: leaderboard.id,
      score: leaderboard.score,
      totalScore: leaderboard.totalScore,
    })))
  }, [])

  useEffect(() => {
    fetchLeaderboard()
  }, [])

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <h1 style={{ marginBottom: 0, marginTop: 16 }}>2048</h1>
        </div>

        <div style={{ fontWeight: "bold", marginBottom: 10 }}>
          By Upnode, Scaled with Celestia
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: 16,
            width: 240,
            fontWeight: "bold",
          }}
        >
          <Link to="/">
            <div className={`nav-button`}>Play</div>
          </Link>
          
          <Link to="/leaderboard">
            <div className={`nav-button active`}>Leaderboard</div>
          </Link>
        </div>
      </div>

      <div style={{
        maxWidth: 400,
        margin: "auto",
      }}>
        {leaderboard.map((item, i) => (
          <div
            key={item.address}
            className="leaderboard-shadow"
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#8F7A66",
              position: "relative",
              borderRadius: 6,
              marginBottom: 12,
            }}
          >
            <div
              style={{
                marginLeft: -16,
                position: "absolute",
                left: 0,
                top: 9,
                textAlign: 'center',
                borderRadius: '50%',
                width: 32,
                height: 32,
                backgroundColor: '#8F7A66',
                color: 'white',
                fontWeight: 'bold',
                paddingTop: 2,
                fontSize: 16,
              }}
            >
              {i + 1}
            </div>

            <div
              style={{
                flexGrow: 1,
                backgroundColor: "white",
                borderRadius: 6,
                padding: '6px 16px',
                paddingLeft: 24,
                lineHeight: '20px',
              }}
            >
              <div style={{ fontSize: 16 }}>
                <b>{addrParse(item.address)}</b>
              </div>

              <div style={{ fontSize: 13 }}>
                Total Score: {item.totalScore >= 1000 ? Math.round(item.totalScore / 1000).toLocaleString('en-US') + 'k' : item.totalScore}
              </div>
            </div>

            {/* <div
              style={{
                width: 40,
                flexBasis: 40,
                flexShrink: 0,
                fontWeight: i < 3 ? "bold" : "normal",
              }}
            >
              {i + 1}
            </div> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
                flexBasis: 50,
                padding: '6px 16px',
                fontSize: 22,
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                {item.score >= 1000 ? Math.round(item.score / 1000).toLocaleString('en-US') + 'k' : item.score}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
/* eslint-enable react/jsx-no-target-blank */
