import React, { useEffect } from "react";
import { useThrottledCallback } from "use-debounce";

import { useGame } from "./hooks/useGame";
import { Board, animationDuration, tileCount } from "../Board";
import { useEndGame } from "../../hooks/useEndGame";
import { useSwipeable } from "react-swipeable";

export const Game = () => {
  const [
    tiles,
    moveLeft,
    moveRight,
    moveUp,
    moveDown,
    chainCreateBoardWrapper,
    cancelMoving,
    score,
    isMoving,
    isInitialized,
    isInitializing,
    boardId,
    loadGame,
    useHotWalletClient,
    hotWalletOutOfFund,
    fundHotWallet,
  ] = useGame();

  const isEndGame = useEndGame(tiles)

  const handleKeyDown = (e: KeyboardEvent) => {
    // disables page scrolling with keyboard arrows
    e.preventDefault();

    switch (e.code) {
      case "ArrowLeft":
        moveLeft();
        break;
      case "ArrowRight":
        moveRight();
        break;
      case "ArrowUp":
        moveUp();
        break;
      case "ArrowDown":
        moveDown();
        break;
    }
  };

  // protects the reducer from being flooded with events.
  const throttledHandleKeyDown = useThrottledCallback(
    handleKeyDown,
    animationDuration,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    window.addEventListener("keydown", throttledHandleKeyDown);

    return () => {
      window.removeEventListener("keydown", throttledHandleKeyDown);
    };
  }, [throttledHandleKeyDown]);

  const handlers = useSwipeable({
    onSwipedLeft: () => moveLeft(),
    onSwipedRight: () => moveRight(),
    onSwipedUp: () => moveUp(),
    onSwipedDown: () => moveDown(),
  });

  return (
    <div {...handlers} style={{ touchAction: 'none' }}>
      <Board
        tiles={tiles}
        tileCountPerRow={tileCount}
        chainCreateBoardWrapper={chainCreateBoardWrapper}
        cancelMoving={cancelMoving}
        score={score}
        isMoving={isMoving}
        isInitalized={isInitialized}
        isInitializing={isInitializing}
        isEndGame={isEndGame}
        boardId={boardId}
        loadGame={loadGame}
        useHotWalletClient={useHotWalletClient}
        hotWalletOutOfFund={hotWalletOutOfFund}
        fundHotWallet={fundHotWallet}
      />
    </div>
  );
};
