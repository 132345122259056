import { useEffect, useState } from "react";
import { TileMeta } from "../components/Tile";

export function checkEndGame(tiles: TileMeta[]): boolean {
  const board = [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ]

  for (const tile of tiles) {
    board[tile.position[1]][tile.position[0]] = tile.value
  }

  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 4; j++) {
      if (board[i][j] == 0) return false;

      if (j > 0) {
        if (board[i][j] == board[i][j-1]) return false;
        if (board[j][i] == board[j-1][i]) return false;
      }
    }
  }

  return true;
}

export function useEndGame(tiles: TileMeta[]): boolean {
  const [ isEndGame, setIsEndGame ] = useState(false)

  useEffect(() => {
    setIsEndGame(checkEndGame(tiles))
  }, [tiles])

  return isEndGame
}
