// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.button {
  background: #8f7a66;
  border: 8px solid #8f7a66;
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #f9f6f2;
  transition: all;
}
.button:hover {
  cursor: pointer;
  transform: scale(1.05);
}
`, "",{"version":3,"sources":["webpack://./src/styles/core.less","webpack://./src/components/Button/button.less"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;ACHF;ADMA;EACE,eAAA;EACA,iBAAA;ACJF;ADOA;EACE,cAAA;ACLF;ADOE;EACE,qBAAA;ACLJ;AAjBA;EACE,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AAmBF;AAhBA;EACE,eAAA;EACA,sBAAA;AAkBF","sourcesContent":["@import \"variables.less\";\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #faf8ef;\n  color: #776e65;\n  font-size: @pixel-size * 2.25;\n  line-height: @pixel-size * 3.5;\n}\n\nh1 {\n  font-size: 4.5 * @pixel-size;\n  line-height: 5.75 * @pixel-size;\n}\n\na {\n  color: #776e65;\n\n  &:hover {\n    text-decoration: none;\n  }\n}\n","@import \"../../styles/index.less\";\n\n.button {\n  background: #8f7a66;\n  border: @pixel-size solid #8f7a66;\n  border-radius: @pixel-size * 0.5;\n  font-size: @pixel-size * 2.5;\n  line-height: @pixel-size * 3;\n  font-weight: bold;\n  color: #f9f6f2;\n  transition: all;\n}\n\n.button:hover {\n  cursor: pointer;\n  transform: scale(1.05);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
