// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.board {
  position: relative;
}
.tile-container {
  position: absolute;
  z-index: 2;
  margin: 8px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.board-overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/core.less","webpack://./src/components/Board/board.less"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;ACHF;ADMA;EACE,eAAA;EACA,iBAAA;ACJF;ADOA;EACE,cAAA;ACLF;ADOE;EACE,qBAAA;ACLJ;AAjBA;EACE,kBAAA;AAmBF;AAhBA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AAkBF;AAfA;EACE,kBAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EAEA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAgBF","sourcesContent":["@import \"variables.less\";\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #faf8ef;\n  color: #776e65;\n  font-size: @pixel-size * 2.25;\n  line-height: @pixel-size * 3.5;\n}\n\nh1 {\n  font-size: 4.5 * @pixel-size;\n  line-height: 5.75 * @pixel-size;\n}\n\na {\n  color: #776e65;\n\n  &:hover {\n    text-decoration: none;\n  }\n}\n","@import \"../../styles/index.less\";\n\n.board {\n  position: relative;\n}\n\n.tile-container {\n  position: absolute;\n  z-index: 2;\n  margin: @pixel-size * 1;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.board-overlay {\n  position: absolute;\n  z-index: 10;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 255, 255, 0.7);\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
