import React from "react"

export default function BoardWaitForTx({ showConfirmText = true }) {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <div className="spinner-border"></div>

      {showConfirmText && (
        <div style={{ fontSize: "1rem", textAlign: "center", marginTop: 16, lineHeight: 1.5 }}>
          Please confirm the transaction<br/>in your wallet
        </div>
      )}
    </div>
  )
}